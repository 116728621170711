import {
  AirBanner,
  airshow3,
  AirshowBnnaer,
  airshowUi1,
  airshowUi2,
  airshowUi4,
  airshowUi5,
  AirUi1,
  AirUi2,
  CharbBanner,
  CharbLogo,
  CharbUi1,
  CharbUi2,
  CharbUi3,
  CharbUi4,
  CharbUi5,
  CharbUi6,
  GarageBanner,
  GarageUi1,
  GarageUi2,
  GarageUi3,
  ilenkardUi1,
  ilenkardUi2,
  ilenkardUi3,
  ilenkardUi4,
  ilenkardUi6,
  IlnkardBanner,
  LegalBanner,
  LegalUi1,
  LegalUi2,
  lenkardLogo,
  NabzBanner,
  NabzUi1,
  NabzUi2,
  StonadeBanner,
  StonadeUi1,
  StonadeUi2,
  TapsiBanner,
  TapsiLogo1,
  TapsiUi1,
  TapsiUi2,
  TapsiUi3,
  TapsiUi4,
  TapsiUi5,
  TapsiUi6,
  tops1,
  tops2,
  TopsBanner,
  Wigo1,
  Wigo2,
  Wigo3,
  Wigo4,
  WigoBanner,
  wigoLogo,
} from "../../../assets/images";

export const productsData = [
  {
    id: 1,
    number: "#1",
    title: "Tops",
    image: tops2,
    banner: TopsBanner,
    technology: "(React native, Nodejs , Golang , AI )",
    category: "App / Ios & Android",
    year: "2020",
    description:
      "Tops Game is a feature-rich educational game for high school students.\nThat is a fully complex project and high-tech service that includes AI and microservices technology.\n\nThis platform could tolerate more than 5M users.\nIt can connect different students with the same characteristics to each other then evaluate them.\n\nAlso, Tops has a unique AI engine that could recommend the best course for each pupil based on his data.",
  },
  {
    id: 2,
    number: "#2",
    title: "Air Properties",
    image: tops2,
    banner: AirBanner,
    technology: "(React , Nodejs , Python , AI )",
    category: "App / PWA",
    year: "2024",
    description:
      "Air Properties is an innovative project powered by LLMs and advanced AI, designed to revolutionize the real estate experience.\n\nThis platform provides personalized home recommendations tailored to user preferences.\n\nUsers can chat with the AI about various properties, receive smart suggestions based on their interests, and even schedule a viewing with an agent seamlessly.\n\nThe AI’s intelligent algorithms ensure it understands user preferences deeply, offering homes that align perfectly with their needs and lifestyle.",
    ui: [AirUi1, AirUi2],
  },
  {
    id: 3,
    number: "#3",
    title: "Tapsi Garage",
    image: tops2,
    banner: GarageBanner,
    technology: "(React , Nodejs)",
    category: "App / PWA , Admin panel",
    year: "2023",
    description:
      "Tapsi Garage is designed to be the ultimate hub for all your car maintenance and repair needs, seamlessly integrated into the Tapsi super app.\n\nThis innovative platform allows you to browse and purchase a wide range of essential car products, including spare parts, maintenance tools, and accessories, all tailored to your specific needs.\n\nBeyond shopping, Tapsi Garage offers a smart and convenient way to connect with professional mechanics near you.\n\nWhether you need assistance with replacing a part, picking up your purchased items, or arranging for delivery directly to your location, the app provides all the support you need.\n\nTapsi Garage is built to save time, reduce hassle, and deliver a smooth, efficient experience for car owners, making vehicle upkeep easier and more accessible than ever.",
    ui: [GarageUi1, GarageUi2, GarageUi3],
  },
  {
    id: 4,
    number: "#4",
    title: "Nabz Energy",
    image: tops2,
    banner: NabzBanner,
    technology: "(React, Nodejs, Microservices)",
    category: "App / PWA , Admin panel",
    year: "2025",
    description:
      "Nabz Energy is an innovative platform designed to encourage users to reduce their gas and electricity consumption through awareness-building and gamification.\nBy leveraging AI and data analytics, the platform helps users understand how to optimize their energy usage efficiently.\n\nIn Nabz Energy, users can monitor their consumption, compete with others, and participate in engaging challenges aimed at reducing energy use.\nThe platform employs incentives such as points, badges, and rewards to boost user engagement.\nAdditionally, it provides smart reports with personalized recommendations to help users lower their energy costs and adopt more efficient consumption habits.\n\nThe ultimate goal of this project is to raise public awareness about energy consumption and promote a more sustainable lifestyle, benefiting both individuals and the environment.",
    ui: [NabzUi1, NabzUi2],
  },
  {
    id: 5,
    number: "#5",
    title: "Tapsi Legal",
    image: tops2,
    banner: LegalBanner,
    technology: "(React , Nodejs)",
    category: "App / Admin panel",
    year: "2024",
    description:
      "Tapsi Legal is a comprehensive platform designed to streamline all legal and contractual processes for Tapsi’s subsidiaries.\n\nThis app provides a centralized solution for managing legal documents, drafting contracts, and overseeing compliance across the organization.\n\nBuilt with scalability and efficiency in mind, Tapsi Legal enables teams to handle their legal workflows seamlessly, reducing administrative overhead and ensuring that all legal operations are carried out in a structured and organized manner.\n\nIt’s an essential tool for enhancing collaboration and maintaining consistency across Tapsi’s diverse business ecosystem.",
    ui: [LegalUi1, LegalUi2],
  },
  {
    id: 6,
    number: "#6",
    title: "Stonade",
    image: tops2,
    banner: StonadeBanner,
    technology: "(React native, React)",
    category: "App / Ios , Android , PWA",
    year: "2024",
    description:
      "Stonade is a modern marketplace platform designed for buying and selling stone products in London. This app serves as a dedicated store where customers can explore a wide range of high-quality stones for various uses, from construction to decoration. Built with cutting-edge technology, Stonade ensures a seamless shopping experience across iOS, Android, and web platforms. It connects buyers and sellers in an intuitive and efficient way, making it easier than ever to find and purchase the perfect stone products for any project.",
    ui: [StonadeUi1, StonadeUi2],
  },
  {
    id: 7,
    number: "#7",
    title: "HR Management",
    image: TapsiLogo1,
    banner: TapsiBanner,
    technology: "(React , Nodejs , PWA )",
    category: "App / UI & UX",
    year: "2023",
    description:
      "Developed using React and Node.js, a versatile panel tailored for companies, akin to SharePoint. Central to the platform is an HR-focused form builder, enabling effortless creation of diverse forms. An integral part of the project is an interactive form editor, crucial for HR operations. The system encompasses a comprehensive employee list, facilitating form assignment. Employees access the panel to complete forms, with results shared among managers and team members. Streamline HR processes with our innovative solution",
    ui: [TapsiUi6, TapsiUi1, TapsiUi2, TapsiUi3, TapsiUi4, TapsiUi5],
  },
  {
    id: 8,
    number: "#8",
    title: "Wigo",
    image: wigoLogo,
    banner: WigoBanner,
    category: "App",
    year: "2021",
    technology: "(React, PWA, Nodejs)",
    description:
      "Introducing our advanced car tracking app, designed to provide you with the most accurate information about your vehicle's whereabouts and performance. Our app is perfect for those who want to keep track of their car's movements and ensure its safety and security at all times. Our app uses state-of-the-art GPS technology, which is attached to your car's OBD port, to provide real-time tracking of your vehicle's location. You can monitor your car's movements from your smartphone or tablet, giving you complete control and peace of mind. In addition, our app provides information about your car's speed, route history, and any stops made along the way. With our app, you can also manage your car's routing and avoid any potential stalling or delays. By providing real-time updates on traffic, road conditions, and weather, you can choose the best route for your car and ensure that you reach your destination safely and efficiently. Our app also provides you with the ability to review your car's history data, giving you insights into your driving habits and any areas for improvement. By analyzing your car's performance over time, you can make informed decisions about maintenance and repairs, saving you time and money in the long run. Overall, our car tracking app is the ultimate tool for anyone who wants to stay informed about their car's movements and performance. With real-time tracking, route management, history data analysis, and speed monitoring, you can be sure that your car is always in safe hands.",
    ui: [Wigo1, Wigo2, Wigo3, Wigo4],
  },
  {
    id: 9,
    number: "#9",
    title: " Airshow  2022",
    image: airshow3,
    banner: AirshowBnnaer,
    category: "App / UI & UX",
    year: "2022",
    technology: "(React, PWA, Nodejs)",
    description:
      "Welcome to our innovative exhibition application! Our app offers a unique experience for both visitors and exhibitors, providing a one-stop-shop for all your exhibition needs. Whether you're attending an event or exhibiting at one, our app has got you covered. For visitors, the app offers a range of features to enhance your experience. You can access live updates from the exhibition, including real-time event schedules and news updates. You can also navigate your way through the exhibition with ease, using our routing feature to guide you to the areas and exhibitors you want to see. Additionally, you can engage with exhibitors directly through the app, allowing you to make connections and gather information about products and services. For exhibitors, the app provides a comprehensive platform to showcase your products and services to a wider audience. You can upload all your files and certificates directly onto the app, making it easier for visitors to access the information they need. You can also use the app to advertise your booth and events, attracting more visitors and increasing your brand visibility. Overall, our exhibition app is designed to streamline the exhibition experience for both visitors and exhibitors. With a range of features including live updates, event schedules, routing, advertising, and direct contact with exhibitors, our app is the perfect tool to make the most out of any exhibition.",
    ui: [airshowUi1, airshowUi2, airshowUi4, airshowUi5],
  },
  {
    id: 10,
    number: "#10",
    title: "iLnekrad",
    image: lenkardLogo,
    banner: IlnkardBanner,
    category: "App",
    year: "2022",
    technology: "(React, PWA, Golang)",
    description:
      "Introducing our state-of-the-art car tracking app! Our app is designed to provide you with a hassle-free and safe driving experience by offering real-time tracking, routing management, and history data analysis. By attaching a GPS device to your car's OBD port, our app can help you track your car's location at any time, anywhere. This means you can easily locate your car in case of theft or misplacement. Additionally, our app can help you monitor your driving speed, ensuring that you stay within the legal limits and drive safely. Our routing management feature is designed to help you navigate your way through unfamiliar roads with ease. By avoiding traffic and other obstacles, our app can help you reach your destination faster and more efficiently. Moreover, our app can help you avoid car theft by alerting you if your car stops or is moved without your permission. With our app, you can also monitor your car's history data, giving you insights into your driving habits and helping you identify any potential issues. This means you can take proactive measures to ensure your car is running smoothly and avoid any breakdowns or accidents. Overall, our car tracking app is the perfect tool for anyone looking to enhance their driving experience. With features like real-time tracking, routing management, history data analysis, and speed monitoring, our app is designed to keep you safe and secure on the road.",
    ui: [ilenkardUi1, ilenkardUi2, ilenkardUi3, ilenkardUi4, ilenkardUi6],
  },
  {
    id: 11,
    number: "#11",
    title: "Charb Chimie",
    image: CharbLogo,
    banner: CharbBanner,
    category: "App / UI & UX",
    year: "2023",
    technology: "(React, PWA, Nodejs)",
    description:
      "Welcome to our web panel for the oleic factory! We understand the importance of managing employee information efficiently and effectively, which is why we have developed a comprehensive platform that includes a variety of features to streamline your human resources processes. With our web panel, employees can access their salary slip, schedule, and organizational chat from a single, user-friendly interface. This makes it easy for them to stay up-to-date with their work-related information and communicate with their colleagues. In addition, our platform includes a robust employee profile feature that allows you to keep track of important details about each member of your team. This can be especially useful for tracking employee performance, identifying training needs, and making informed decisions about promotions and other career opportunities. One of the key features of our web panel is the internal LMS (Learning Management System) that we have developed to help train new employees. This feature allows you to create and deliver customized training programs for new hires, ensuring that they have the knowledge and skills they need to succeed in their roles. Overall, our web panel is designed to help you manage your human resources processes more efficiently and effectively, allowing you to focus on what really matters - building a strong and productive workforce.",
    ui: [CharbUi1, CharbUi2, CharbUi3, CharbUi4, CharbUi5, CharbUi6],
  },
];
