import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "../../assets/icons";
import {
  AirBanner,
  airshow3,
  AirshowBnnaer,
  airshowUi1,
  airshowUi2,
  airshowUi4,
  airshowUi5,
  AirUi1,
  AirUi2,
  CharbBanner,
  CharbLogo,
  CharbUi1,
  CharbUi2,
  CharbUi3,
  CharbUi4,
  CharbUi5,
  CharbUi6,
  GarageBanner,
  GarageUi1,
  GarageUi2,
  GarageUi3,
  ilenkardUi1,
  ilenkardUi2,
  ilenkardUi3,
  ilenkardUi4,
  ilenkardUi6,
  IlnkardBanner,
  LegalBanner,
  LegalUi1,
  LegalUi2,
  lenkardLogo,
  StonadeBanner,
  StonadeUi1,
  StonadeUi2,
  TapsiBanner,
  TapsiLogo1,
  TapsiUi1,
  TapsiUi2,
  TapsiUi3,
  TapsiUi4,
  TapsiUi5,
  TapsiUi6,
  tops1,
  tops2,
  TopsBanner,
  Wigo1,
  Wigo2,
  Wigo3,
  Wigo4,
  WigoBanner,
  wigoLogo,
} from "../../assets/images";
import DetailProduct from "../../components/DetailProduct";
import StarrySky from "../../components/starBackground/star";
import { productsData } from "./json/products";

const ProductsList = ({ fullpageApi }: any) => {
  return (
    <div
      onWheel={(e) => {
        if (e.deltaY < 0) {
          fullpageApi.moveSlideLeft();
        }
      }}
      className="productsContainer">
      <StarrySky />
      <List />
    </div>
  );
};

const List = () => {
  const [imageUrl, setImageUrl] = useState(-1);
  const [imageUrlLeave, setImageUrlLeave] = useState(-1);
  const [data, setData] = useState();
  const handelImageHover = (index: number) => {
    setImageUrl(index);
  };

  const handelRemoveImage = (index: number) => {
    setImageUrl(-1);
    setImageUrlLeave(index);
  };

  const goProject = (i: any) => {
    window.location.href = `/product/${i.id}`;
    setData(i);
    localStorage.setItem("data", JSON.stringify(i));
  };

  return (
    <div className="productsPageContainer1">
      {/* <div className="titlePageScrollProducts">#PRODUCTS</div> */}
      <div className="productsListContainer">
        <div className="productsListSections">
          <div className="productsList">
            {productsData?.map((i, index) => (
              <div
                onMouseEnter={() => handelImageHover(index)}
                onMouseLeave={() => handelRemoveImage(index)}
                className="productsItem pointer"
                onClick={() => goProject(i)}>
                <div className="productsItemPartFirst">
                  <div className="productsItemNumber">{i.number}</div>
                  <div className="productsItemTitle">{i.title}</div>
                </div>
                <img className="arrowListProducts" src={ArrowRight} />
              </div>
            ))}
          </div>
          {/* <div className="productsImgHoverContainer">
            <div className="productsImgHover">
              {Item.map((i, index) => (
                <img
                  className={
                    imageUrl === index
                      ? "productsLogoShow"
                      : imageUrlLeave === index
                      ? "productsLogoLeave"
                      : "productsLogoHide"
                  }
                  src={i.image}
                />
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProductsList;
